import styled from 'styled-components';

import Bg1 from '../../assets/img/home-img/bg-1.png';
import Bg2 from '../../assets/img/home-img/bg-2.png';

export const MainContainer = styled.div`
  background-image: url(${Bg1});
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;

  @media only screen and (min-width: 760px) {
    padding: 60px 0 60px 0;

    .desktop-logo-pana {
      position: absolute;
      right: 0;
      display: flex;
      justify-content: flex-end;
      margin-top: 35px;
    }

    #header {
      text-align: right;
    }

    #o-que-e .limitado {
      margin-top: -20px;
      font-size: 30px;
    }

    #o-que-e .periodo {
      font-weight: bold;
      font-size: 30px;
    }
  }

  @media only screen and (max-width: 760px) {
    padding: 20px 0 20px 0;

    #o-que-e .limitado {
      margin-top: -20px;
      font-size: 20px;
    }

    #o-que-e .periodo {
      margin-top: 30px;
      font-weight: bold;
      font-size: 22px;
    }

    .navbar {
      background: none !important;
    }

    .navbar .navbar-toggler-icon {
      background-color: #9ddcf9;
      border-radius: 4px;
    }
  }

  #logo-header {
    height: 40px;
    width: 250px;
  }

  #logo-header-mobile {
    height: 40px;
    width: 250px;
    margin: 20px 0 0 0;
  }

  #img-destaque {
    width: 100%;
    height: 100%;
  }

  #btn-regulamento {
    padding-right: 5px !important;
  }

  #header a {
    color: #fff;
    font-size: 15px;
    text-decoration: none;
  }

  #header button {
    color: #fff;
    font-size: 15px;
    text-decoration: none;
    background: none;
    border: none;
    padding: 0;
    margin: 0;
  }

  #panel-number-box-img {
    @media only screen and (min-width: 760px) {
      margin-top: -170px;
      margin-bottom: 100px;
    }
    @media only screen and (max-width: 760px) {
      margin-top: -100px;
      margin-bottom: 100px;
    }
  }

  #panel-number-box-img span {
    font-size: 60px;
    background-color: black;
    margin: 0 6px 0 6px;
    padding: 10px 15px 10px 15px;
    border-radius: 20px;
  }

  #panel-number-box-img-mobile span {
    font-size: 30px;
    background-color: black;
    margin: 0 6px 0 6px;
    padding: 10px 15px 10px 15px;
    border-radius: 20px;
  }

  #total-disponivel-mobile {
    margin-top: 40px;
    font-size: 20px;
    font-weight: bold;
  }

  .criado-pra-voce {
    @media only screen and (min-width: 760px) {
      text-align: right;
      margin-top: 30px;
    }
    @media only screen and (max-width: 760px) {
      text-align: center;
      margin-bottom: 50px;
    }
  }
`;

export const SecondContainer = styled.div`
  background-image: url(${Bg2});
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;

  @media only screen and (min-width: 760px) {
    padding: 60px 0 60px 0;
  }

  @media only screen and (max-width: 760px) {
    padding: 20px 0 20px 0;
  }

  .react-player {
    width: 100% !important;
    height: 100% !important;
    min-height: 600px !important;
  }

  .periodo {
    font-size: 20px;
    @media only screen and (max-width: 760px) {
      font-size: 20px;
    }
    margin: 0;
  }

  .limitado {
    font-size: 18px;
    @media only screen and (max-width: 760px) {
      font-size: 18px;
    }
    margin: 0;
  }

  #btn-passos {
    font-size: 14px;
    background-color: #9ddcf9;
    color: #073a6a;
    font-weight: bold;
    border-radius: 4px;
    border: 0px;
    padding: 8px 0 8px 0;
    width: 100%;
    /* font-family: 'openSans'; */
    margin-top: 20px;
  }

  .participate-box {
    @media only screen and (max-width: 760px) {
      margin-bottom: 10px;
    }

    .icon-box {
      background-color: #2f4f89;
      border-radius: 40px 40px 0 0;
      padding: 30px;
      @media only screen and (max-width: 760px) {
        padding: 0;
      }
    }

    .text-box {
      background-color: #18305c;
      border-radius: 0 0 40px 40px;
      font-size: 16px;
      font-weight: bold;
      padding: 25px;
      min-height: 150px;

      @media only screen and (max-width: 760px) {
        min-height: 60px;
        padding: 18px;
        font-size: 18px;
      }

      p {
        margin: 0;
        padding: 0;
      }

      #text-onde {
        font-size: 12px;
        color: #9ddcf9;
        margin-top: 10px;
      }
    }
  }

  .title {
    color: #9ddcf9;
    font-weight: bold;
    font-size: 33px;
  }

  .btn-participe {
    font-size: 26px;
    @media only screen and (max-width: 760px) {
      font-size: 20px;
    }
    background-color: #9ddcf9;
    color: #073a6a;
    font-weight: bold;
    border-radius: 4px;
    border: 0px;
    padding: 6px 0 6px 0;
    width: 100%;
    /* font-family: 'openSans'; */
    margin-top: 20px;
  }
`;

export const ThirdContainer = styled.div`
  background-image: url(${Bg1});
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;

  @media only screen and (min-width: 760px) {
    padding: 60px 0 20px 0;
  }

  @media only screen and (max-width: 760px) {
    padding: 20px 0 20px 0;
  }

  #logo-footer {
    height: 30px;
    width: 200px;
  }

  .title {
    color: #9ddcf9;
    font-weight: bold;
    font-size: 33px;
  }

  .d-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .card-description {
    font-size: 18px;
    width: 50%;
    @media only screen and (max-width: 760px) {
      font-size: 20px;
      width: 100%;
    }
  }

  #footer {
    p {
      font-size: 18px;
      margin-top: 12px;
      @media only screen and (max-width: 760px) {
        font-size: 14px;
        margin-top: 0;
      }
    }
  }

  #produtos-participantes {
    width: 80%;
    color: #073a6a;
    background-color: #9dddf9;
    font-weight: bold;
    border: 0;
    border-radius: 4px;
    @media only screen and (max-width: 760px) {
      width: 100%;
      font-size: 18px;
    }
  }

  #produtos-nao-participantes {
    width: 80%;
    color: #9ddcf9;
    background-color: #07396a;
    font-weight: bold;
    border: 0;
    border-radius: 4px;
    @media only screen and (max-width: 760px) {
      width: 100%;
      margin-top: 10px;
      font-size: 18px;
    }
  }

  .accordion-item {
    border-radius: 100%;
    margin-bottom: 10px;
    background-color: #063664;
  }

  .accordion-header {
    border-radius: 12px;

    button {
      border-radius: 12px !important;
    }
  }

  .text-right {
    text-align: right;
  }

  .accordion-item button {
    background-color: #9dddf9;
    color: #07396a;
    font-weight: bold;
    border-radius: 12px;
    font-size: 25px;
    @media only screen and (max-width: 760px) {
      font-size: 18px;
    }
  }

  .accordion-body {
    font-size: 18px;
    @media only screen and (max-width: 760px) {
      font-size: 16px;
    }
    color: #fff;
    background-color: #063664;
    margin-top: -3px;
    border-radius: 0 0 12px 12px;
  }

  .d-align-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  img {
    width: 100%;
  }

  #duvidas {
    @media only screen and (min-width: 760px) {
      margin-top: 80px;
    }
    @media only screen and (max-width: 760px) {
      margin-top: 40px;
    }
  }
`;

export const ModalStyle = styled.div`
  .modal-title {
    color: #9dddf9;
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .table-politica {
    border-collapse: separate;
    border-spacing: 0 50px;
  }

  .modal-body {
    background-color: #030406;
    color: #fff;
    font-size: 18px;
    padding: 20px;
    border-radius: 12px;
  }

  .btn-close-modal {
    background: none;
  }

  .regulamento-title {
    font-weight: bold;
  }
`;

export const ModalStyleComoParticipar = styled.div`
  .modal-body {
    background-color: #080910;
    color: #fff;
    font-size: 18px;
    padding: 20px;
    border-radius: 12px;
  }

  .btn-close-modal {
    background: none;
    border: none;
  }

  strong {
    color: #9dddf9;
  }

  .color-blue {
    color: #9dddf9;
  }

  @media only screen and (max-width: 760px) {
    img {
      width: 100%;
    }
  }

  p {
    margin: 10px 0 10px 0;
  }
`;

export const ModalStyleProdutos = styled.div`
  .modal-body {
    background-color: #080910;
    color: #fff;
    font-size: 18px;
    border-radius: 12px;
  }

  .btn-close-modal {
    background: none;
    border: none;
  }

  .table-responsive {
    border-radius: 12px;
  }

  table th {
    font-size: 20px;
    font-weight: bold;
    color: #9dddf9;
    @media only screen and (max-width: 760px) {
      font-size: 14px;
    }
  }

  table tbody {
    color: #9dddf9;
    font-size: 16px;
    @media only screen and (max-width: 760px) {
      font-size: 12px;
    }
  }

  table thead {
    background-color: #07396a;
  }

  table td {
    border-bottom: none;
  }

  .table-responsive {
    max-height: 600px;
  }

  .p-th {
    padding: 10px;
  }
`;
