import React from 'react';
import { Route, Routes as ReactDOMRoutes } from 'react-router-dom';

import Home from '../pages/Home';

const Routes = () => (
  <ReactDOMRoutes>
    <Route index element={<Home />} />
  </ReactDOMRoutes>
);

export default Routes;
