import { createGlobalStyle } from 'styled-components';

import dinotFont from '../fonts/DinotFont.woff';
import openSans from '../fonts/OpenSans-Regular.ttf';

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'dinotfont';   
    src: url(${dinotFont}) format('woff');
  }

  @font-face {
    font-family: 'openSans';
    src: url(${openSans}) format('ttf');
  }

  body {
    background: #FFF;
    color: #3a3a3a;
    -webkit-font-smoothing: antialiased;
    font-family: 'dinotfont';
  }
  
  body, input, button { 
    font-size: 26px;
  }
  
  button {
    cursor: pointer;
  }

  a {
    cursor: pointer;
  }

  .modal-bootstrap-custom .modal-content {
    border-radius: 16px !important;
  }
  
  .d-mobile-none {
    @media only screen and (max-width: 720px) {
      display:none;
    }
  }

  .d-desk-none {
    @media only screen and (min-width: 720px) {
      display:none;
    }
  }

  .text-align-right {
    text-align: right;
  }
`;
