/* eslint-disable prettier/prettier */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/no-unescaped-entities */
import React, { useState, useEffect } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import ReactPlayer from 'react-player/vimeo';
import Modal from 'react-bootstrap/Modal';
import api from '../../services/api';

import ImgDestaque from '../../assets/img/home-img/img-destaque.png';
import LogoFooter from '../../assets/img/home-img/logo-footer.png';
import ModalCloseBtn from '../../assets/img/home-img/modal-close-btn.png';
import ImgIconBag from '../../assets/img/home-img/icon-bag.png';
import ImgIconRefund from '../../assets/img/home-img/icon-refund.png';
import ImgIconGift from '../../assets/img/home-img/icon-gift-box.png';
import Passo1 from '../../assets/img/home-img/passo1.png';
import Passo2 from '../../assets/img/home-img/passo2.png';
import Passo3 from '../../assets/img/home-img/passo3.png';
import Passo4 from '../../assets/img/home-img/passo4.png';
import PraVoce from '../../assets/img/home-img/criado-pra-voce.png';
import LogoPanaclub from '../../assets/img/home-img/logo-panaclub-criado.png';
import RetornoCashback from '../../assets/img/home-img/retorno-cashback.png';
import TotalDisponivelBox from '../../assets/img/home-img/total-disponivel-box.png';

import {
  MainContainer,
  SecondContainer,
  ThirdContainer,
  ModalStyle,
  ModalStyleComoParticipar,
  ModalStyleProdutos,
} from './styles';

const ModalPolitica = (props) => (
  <Modal
    {...props}
    size="xl"
    aria-labelledby="modalPolitica"
    centered
    dialogClassName="modal-bootstrap-custom"
  >
    <ModalStyle>
      <Modal.Body>
        <div className="row">
          <div className="col-9">
            <p className="modal-title">POLÍTICA DE PRIVACIDADE</p>
          </div>
          <div className="col-3 text-align-right">
            <button
              type="button"
              className="btn-close-modal"
              onClick={() => props.setShowModalPolitica(false)}
            >
              <img src={ModalCloseBtn} alt="" />
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <p className="politica-text">
              A Panasonic do Brasil Limitada, sociedade empresária com sede na
              Rua Matrinxã, nº 1.155,Distrito Industrial, Manaus/AM, CEP
              69075-150, inscrita no CNPJ/MF sob o nº 04.403.408/0001-65, preza
              pela privacidade e proteção dos dados pessoais de seus clientes e
              consumidores ("<strong>Você</strong>" ou <strong>Usuários</strong>
              ).
            </p>
            <p className="politica-text">
              Por meio da presente Política de Privacidade (Política), queremos
              lhe explicar como utilizamos seus Dados Pessoais para a realização
              da presente ação comercial (“Campanha”). Também lhe explicaremos
              quais os seus direitos como titular de dados e como você pode
              exercê-los.
            </p>
            <p className="politica-text">
              Se você ficar com alguma dúvida, entre em contato através dos
              canais indicados no item 10.
            </p>
            <p className="politica-title">
              1- Para participar da Campanha, você declara:
            </p>
            <p className="politica-text">
              (i) ser maior de 18 (dezoito) anos; e
            </p>
            <p className="politica-text">
              (ii) ter feito a leitura completa e atenta das regras deste
              documento e do Regulamento, estando plenamente ciente sobre os
              respectivos conteúdos,
            </p>
            <p className="politica-text">
              (iii) ter compartilhado conosco apenas dados e informações
              verdadeiras
            </p>
            <p className="politica-title">
              2 - Para participar da Campanha, você declara:
            </p>
            <p className="politica-text">
              (i) quando você realiza, voluntariamente, seu cadastro no website
              da Campanha e compartilha os demais dados necessários à realização
              desta;
            </p>
            <p className="politica-text">
              (ii) quando você os fornece através dos canais de atendimento da
              Campanha;
            </p>
            <p className="politica-text">
              (iii) através de cookies, tags e tecnologias semelhantes;
            </p>
            <p className="politica-title">
              3 - Os dados coletados no âmbito da presente Campanha serão
              utilizados exclusivamente para a consecução das finalidades
              indicadas abaixo:
            </p>
            <p className="politica-text">
              <table className="table-politica">
                <thead>
                  <th>Dados Coletados</th>
                  <th>Finalidades</th>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      Nome completo, e-mail, telefone, endereço com CEP, Razão
                      Social, CNPJ, data de nascimento do representante legal e
                      dados de compra de produtos Panasonic.{' '}
                    </td>
                    <td>
                      Validar sua identidade, permitir que você acesse a área
                      logada do website e participe da Campanha.
                      <br />
                      Enviar comunicações exclusivamente relacionadas ao
                      desenvolvimento da Campanha, de modo que os dados
                      coletados no âmbito dela não serão utilizados para o envio
                      de comunicações sobre promoções e/ou canais de venda da
                      Panasonic.{' '}
                      <br />
                      Prestar atendimento relacionado à Campanha, inclusive via
                      SMS.{' '}
                      <br />
                      Permitir o cumprimento de obrigações legais e regulatórias
                      pela Panasonic e resguardar os direitos desta.{' '}
                      <br />
                      Realizar análises de prevenção à fraude, proteção do
                      crédito e riscos associados.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Data de nascimento do Representante Legal, se a
                      participação for de Pessoa Jurídica.
                    </td>
                    <td>
                      Realizar estudos internos sobre a Campanha, com o intuito
                      de aprimorar nossos produtos, serviços e operações.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Dados de navegação, como endereço IP, data e hora de
                      acesso, tipo de navegador, duração da visita, páginas
                      visitadas. Dados sobre o dispositivo de acesso como
                      modelo, fabricante, sistema operacional, operadora de
                      telefonia, tipo de navegador, tipo e velocidade da
                      conexão.{' '}
                    </td>
                    <td>
                      Registrar o seu IP, data e hora de acesso, para o
                      cumprimento de obrigações legais.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Dados de navegação, como endereço IP, data e hora de
                      acesso, tipo de navegador, duração da visita, páginas
                      visitadas. Dados sobre o dispositivo de acesso como
                      modelo, fabricante, sistema operacional, operadora de
                      telefonia, tipo de navegador, tipo e velocidade da
                      conexão.{' '}
                    </td>
                    <td>
                      Ativar funcionalidades essenciais, como software
                      antivírus, apresentação do conteúdo na tela, entre outras
                      funções.{' '}
                      <br />
                      Impedir atividades fraudulentas e melhorar a segurança da
                      Plataforma.
                      <br />
                      Entender seu comportamento de navegação e como o website
                      está sendo usado, para melhorar sua experiência como
                      usuário. Os dados coletados para essa finalidade são
                      agregados e, sempre que possível, anonimizados.{' '}
                    </td>
                  </tr>
                </tbody>
              </table>
            </p>
            <p className="politica-title">
              4 - Cookies e tecnologias semelhantes
            </p>
            <p className="politica-text">
              Utilizamos cookies e tecnologias semelhantes, tais como tags e
              pixels (Cookies) para coletar informações sobre seu navegador,
              dispositivo e demais dados de navegação. Eles nos permitem saber
              como e quando o website é acessado, bem como quantas pessoas o
              acessam. Eles podem ser úteis para, por exemplo, fazermos o
              website encaixar na sua tela, entender melhor a utilização do
              website. Utilizamos os seguintes tipos de cookies:
            </p>
            <p className="politica-text padding-left">
              Cookies essenciais: São os Cookies estritamente necessários para
              fornecer nossos serviços e para que o website funcione
              corretamente, garantindo a segurança da navegação, o
              dimensionamento do conteúdo e o cumprimento de obrigações legais
              pela Panasonic.
            </p>
            <p className="politica-text padding-left">
              Cookies de publicidade: São Cookies usados para direcionamento de
              conteúdos e publicidade conforme o seu perfil e preferências. Eles
              servem para que você veja anúncios mais relevantes e mais
              interessantes durante a sua navegação.
            </p>
            <p className="politica-text padding-left">
              Cookies estatísticos (analytics): Fornecem informações sobre seu
              comportamento de navegação e como o website está sendo usado. Os
              dados coletados são agregados e nosso objetivo é entender melhor o
              nosso público, para que possamos oferecer conteúdo, serviços e
              produtos mais interessantes para quem acessa nosso website.
            </p>
            <p className="politica-text">
              Se você quiser saber quais cookies estão instalados no seu
              dispositivo, ou se deseja excluí-los ou restringi-los, use a
              configuração do seu navegador. Você encontrará mais explicações
              sobre como proceder clicando nos links abaixo. Para encontrar
              informações relacionadas a outros navegadores, visite o site do
              desenvolvedor do navegador.
            </p>
            <p className="politica-text padding-left"> Firefox </p>
            <p className="politica-text padding-left"> Chrome </p>
            <p className="politica-text padding-left"> Safari </p>
            <p className="politica-text padding-left"> Internet Explorer </p>
            <p className="politica-text">
              Lembre-se que o uso de cookies nos permite oferecer uma melhor
              experiência em nossos produtos e serviços. Se você bloquear
              cookies em nosso website, não podemos garantir o correto
              funcionamento de todas as funcionalidades e talvez você não
              consiga acessar determinadas áreas e/ou páginas. Além disso, é bem
              provável que certas funções e páginas não funcionem da maneira
              adequada.{' '}
            </p>
            <p className="politica-title">
              5 - Os seus direitos como titular de dados{' '}
            </p>
            <p className="politica-text">
              Você tem alguns direitos relativos aos dados que tratamos. Abaixo,
              trazemos uma lista deles, além de informações sobre como você pode
              exercê-los. Ao recebermos a sua solicitação, poderemos pedir
              algumas informações adicionais para verificarmos sua identidade e
              analisarmos seu pedido. Para exercer quaisquer desses direitos,
              envie um e-mail para{' '}
              <strong>atendimento@webpremios.com.br</strong> aos cuidados do
              Encarregado de Proteção de Dados.{' '}
            </p>
            <p className="politica-text">
              <table className='table-politica'>
                <tbody>
                  <tr>
                    <td>Confirmação e Acesso</td>
                    <td>
                      Permite que você possa verificar se tratamos dados
                      pessoais seus e, em caso positivo, requisitar uma cópia
                      dos dados pessoais que nós temos sobre você.
                    </td>
                  </tr>
                  <tr>
                    <td>Correção</td>
                    <td>
                      Permite que você corrija alguns dos seus dados pessoais
                      incompletos, inexatos ou desatualizados por meio do seu
                      próprio cadastro no site.
                    </td>
                  </tr>
                  <tr>
                    <td>Anonimização, bloqueio ou eliminação </td>
                    <td>
                      Permite que você nos peça, quando os dados tratados sejam
                      desnecessários, excessivos ou tratados em desconformidade
                      com a lei, para (a) anonimizar seus dados, de forma a que
                      eles não possam mais ser relacionados a você e, portanto,
                      deixem de ser dados pessoais; (b) bloquear seus dados,
                      suspendendo temporariamente a sua possibilidade de
                      tratarmos; e (c) eliminar seus dados, caso em que
                      apagaremos todos os seus dados sem possibilidade de
                      reversão, salvo nos casos previstos em lei e quando a
                      manutenção for necessária para o cumprimento de obrigação
                      legal ou regulatória.
                    </td>
                  </tr>
                  <tr>
                    <td>Portabilidade </td>
                    <td>
                      Você tem o direito de solicitar, mediante requisição
                      expressa e dentro dos parâmetros estabelecidos pela
                      Autoridade Nacional de Proteção de Dados (ANPD), que seja
                      fornecido a você, ou a terceiro que você escolher, os seus
                      dados pessoais em formato estruturado e interoperável,
                      para transferência a outra empresa, desde que não viole a
                      propriedade intelectual ou segredo de negócios da empresa.
                    </td>
                  </tr>
                  <tr>
                    <td>Informação sobre o compartilhamento </td>
                    <td>
                      Você tem o direito de saber as entidades públicas e
                      privadas com as quais realizamos uso compartilhado de
                      dados.
                    </td>
                  </tr>
                  <tr>
                    <td>Informação sobre a possibilidade de não consentir </td>
                    <td>
                      Permite que você tenha informações claras e completas
                      sobre a possibilidade e as consequências de não fornecer
                      consentimento. O seu consentimento, quando necessário,
                      deve ser livre e informado. Portanto, sempre que pedirmos
                      seu consentimento, você poderá negá-lo – ainda que, nesses
                      casos, é possível que tenhamos que limitar alguns
                      serviços.
                    </td>
                  </tr>
                  <tr>
                    <td>Revogação do consentimento </td>
                    <td>
                      Você tem o direito de retirar o seu consentimento em
                      relação às atividades de tratamento que se baseiam no
                      consentimento. No entanto, isso não afetará a legalidade
                      de qualquer tratamento realizado anteriormente. Se você
                      retirar o seu consentimento, talvez não possamos fornecer
                      determinadas comunicações ou serviços para você, mas
                      iremos avisá-lo quando isso ocorrer.
                    </td>
                  </tr>
                </tbody>
              </table>
            </p>
            <p className="politica-title">
              6 - Com quem compartilhamos os seus dados?
            </p>
            <p className="politica-text">
              A Panasonic opera em parceria com outras empresas para o
              desenvolvimento desta Campanha e pode compartilhar com elas seus
              dados pessoais, sempre dentro dos limites das finalidades
              informadas no tópico 3 acima e de forma a preservar ao máximo a
              sua privacidade:
            </p>
            <p className="politica-text">
              <strong>Fornecedores e Parceiros</strong>: Contamos com a ajuda de
              fornecedores e parceiros que podem tratar dados pessoais que
              coletamos. As informações são divulgadas apenas para as
              finalidades dos serviços prestados. Sempre buscamos avaliar
              cuidadosamente nossos fornecedores e firmar com eles obrigações
              contratuais de proteção de dados pessoais e segurança da
              informação, com o objetivo de minimizar riscos para os titulares.
              Entre esses fornecedores, estão, por exemplo: agências de
              publicidade; servidores de hospedagem de dados; bancos e
              Instituições Financeiras.
            </p>
            <p className="politica-text">
              <strong>Autoridades públicas</strong>: podemos compartilhar
              quaisquer dados pessoais necessários ao cumprimento de obrigações
              legais ou regulatórias ou, ainda, proteger os nossos direitos e
              dos nossos colaboradores. Além disso, também podemos ser obrigados
              a compartilhar alguns de seus dados pessoais com entidades
              públicas para cumprir obrigações legais ou regulatórias ou para
              cumprir ordens de autoridades competentes
            </p>
            <p className="politica-text">
              <strong>Outros países</strong>: Ainda que a Campanha seja
              destinada a pessoas localizadas no Brasil, a Panasonic é uma
              empresa global, de modo que os dados pessoais podem ser
              transferidos para empresas e fornecedores localizados em outros
              países, como Japão e Estados Unidos. Adotamos as salvaguardas
              adequadas para que essa transferência seja realizada de forma
              segura e que seus direitos como titular dos dados pessoais sejam
              resguardados.
            </p>
            <p className="politica-title">
              7 - Quais são nossas responsabilidades e como protegemos os seus
              dados pessoais?
            </p>
            <p className="politica-text">
              Nossa responsabilidade é cuidar dos seus dados pessoais, e
              utilizá-los para finalidades lícitas, conforme descrito nesta
              Política. Para garantir a sua privacidade e a proteção dos seus
              dados pessoais, adotamos as práticas de segurança adequadas para
              nosso mercado, com o uso de técnicas de criptografia e outros
              sistemas de segurança da informação.
            </p>
            <p className="politica-text">
              Nós trabalhamos para preservar a sua privacidade e proteger os
              seus dados pessoais, mas infelizmente não podemos garantir
              segurança absoluta. Entrada ou uso não autorizado de terceiros em
              sua conta, falha de hardware ou software que não esteja sob
              controle da Panasonic e outros fatores podem comprometer a
              segurança dos seus dados pessoais. Por isso, sua atuação é
              fundamental para a manutenção de um ambiente seguro para todos.
              Você pode nos ajudar adotando boas práticas de segurança em
              relação à sua conta e aos seus dados e caso você tome conhecimento
              de algo que comprometa a segurança dos seus dados, por favor,
              entre em contato conosco por meio do nosso Encarregado (Data
              Protection Officer), cujos canais de contato se encontram abaixo.
            </p>
            <p className="politica-title">
              8 - Por quanto tempo os dados pessoais serão armazenados?
            </p>
            <p className="politica-text">
              A Panasonic tem uma política com regras sobre a retenção e o
              descarte ou anonimização de dados pessoais. Ela define as
              diretrizes para determinar o período de retenção adequado para
              cada tipo de dado pessoal coletado, considerando a sua natureza,
              necessidade de coleta e finalidade para a qual ele será tratado.
              Dados pessoais são armazenados somente pelo tempo que for
              necessário para cumprir com as finalidades para as quais foram
              coletados, salvo se houver qualquer outra razão para sua
              manutenção, por exemplo, cumprimento de quaisquer obrigações
              legais, regulatórias, contratuais, entre outras, desde que
              fundamentadas com uma base legal.
            </p>
            <p className="politica-title">9 - Informações Gerais</p>
            <p className="politica-text">
              <strong>Alterações na Política de Privacidade </strong>
            </p>
            <p className="politica-text">
              Como estamos sempre buscando melhorar nossos Produtos e
              Experiências, esta Política de Privacidade pode passar por
              atualizações para refletir as melhorias realizadas, bem para
              permitir a adequação a novas disposições legais e regulamentares
              aplicáveis. Desta forma, recomendamos a visita periódica desta
              página para que você tenha conhecimento sobre as modificações
              efetivadas. Versão atual publicada em: Novembro de 2022
            </p>
            <p className="politica-text">
              <strong>Lei aplicável e jurisdição </strong>
            </p>
            <p className="politica-text">
              A presente Política de Privacidade será regida, interpretada e
              cumprida segundo a legislação brasileira. O foro competente para
              dirimir qualquer litígio ou controvérsia envolvendo o presente
              documento será definido conforme a legislação aplicável.
            </p>
            <p className="politica-title">10 - Dúvidas e Contato</p>
            <p className="politica-text">
              Se quiser exercer algum direito previsto no item 5 acima ou tiver
              dúvidas, comentários ou sugestões relacionadas a esta Política
              envie um e-mail para atendimento@webpremios.com.br aos cuidados do
              Encarregado de Proteção de Dados.
            </p>
            <p className="politica-title">10 - Vocabulário</p>
            <p className="politica-text">
              A fim de descomplicar a sua leitura, apresentamos algumas
              definições úteis para a sua interpretação:
            </p>
            <p className="politica-text">
              <strong>Website: </strong>designa o endereço eletrônico
              www.cashbackpanasonic.com.br e seus subdomínios.
            </p>
            <p className="politica-text">
              <strong>Dado Pessoal: </strong>É o dado relativo a uma pessoa
              física e que seja capaz de identificar a pessoa ou tornar possível
              a sua identificação. São exemplos de Dados Pessoais que permitem a
              sua identificação: Nome, endereço, telefone celular, e-mail etc.
            </p>
            <p className="politica-text">
              <strong>Dado Anonimizado: </strong> É qualquer informação que
              perde a capacidade de identificar uma pessoa física.
            </p>
            <p className="politica-text">
              <strong>Encarregado de Proteção de Dados (DPO): </strong> é aquele
              que atua como canal de comunicação entre o Grupo Panasonic, os
              Titulares dos Dados e a Autoridade Nacional de Proteção de Dados
              (ANPD), quando o assunto é a proteção dos seus Dados Pessoais.
            </p>
            <p className="politica-text">
              <strong>Endereço IP: </strong> Abreviatura de Internet Protocol. É
              o conjunto alfanumérico que identifica os dispositivos dos
              Usuários na Internet.
            </p>
            <p className="politica-text">
              <strong>Grupo Panasonic: </strong> Para os fins do presente
              Política, considera-se a Panasonic do Brasil Limitada, sociedade
              empresária com sede na Rua Matrinxã, nº 1.155, Distrito
              Industrial,Manaus/AM, CEP 69075-150, inscrita no CNPJ/MF sob o nº
              04.403.408/0019-94, e todas sociedades nas quais ela detém
              participação societária direta ou indiretamente, e/ou com sócio em
              comum, existentes ou que venham a existir.
            </p>
            <p className="politica-text">
              <strong>Lei Geral de Proteção de Dados ou LGPD: </strong> Lei nº
              13.709/18, que dispõe sobre o Tratamento de Dados Pessoais, com o
              objetivo de proteger os direitos fundamentais de liberdade e de
              privacidade e o livre desenvolvimento da personalidade da pessoa
              natural.
            </p>
            <p className="politica-text">
              <strong>Marcas: </strong> Panasonic.
            </p>
            <p className="politica-text">
              <strong>Produtos: </strong> Produtos e tecnologias oferecidos pela
              marca Pansonic.
            </p>
            <p className="politica-text">
              <strong>Titular de Dados: </strong> É você, a pessoa física a quem
              os Dados Pessoais se referem.
            </p>
            <p className="politica-text">
              <strong>Tratamento de Dados Pessoais: </strong> são os usos que
              nós fazemos dos seus Dados Pessoais, incluindo, mas não se
              limitando às seguintes atividades: coleta, armazenamento,
              consulta, compartilhamento, classificação, reprodução e avaliação
              destes.
            </p>
            <p className="politica-text">
              <strong>Usuários: </strong> é você, a pessoa física que
              participante da Campanha.
            </p>
          </div>
        </div>
      </Modal.Body>
    </ModalStyle>
  </Modal>
);

const ModalRegulamento = (props) => (
  <Modal
    {...props}
    size="lg"
    aria-labelledby="modalRegulamento"
    centered
    dialogClassName="modal-bootstrap-custom"
  >
    <ModalStyle>
      <Modal.Body>
        <div className="row">
          <div className="col-9">
            <p className="modal-title">REGULAMENTO - CASHBACK PANASONIC</p>
          </div>
          <div className="col-3 text-align-right">
            <button
              type="button"
              className="btn-close-modal"
              onClick={() => props.setShowModalRegulamento(false)}
            >
              <img src={ModalCloseBtn} alt="" />
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <p className="regulamento-title">1. CAMPANHA</p>
            <p className="regulamento-text">
              1.1. A Campanha PANACLUB – Plataforma cashback para consumidores finais – é de responsabilidade da PANASONIC DO BRASIL LIMITADA., empresa com sede na Rua Matrinxã, 1155, Distrito Industrial Mal. Castelo Branco, CEP 69075-150, Manaus/AM, inscrita no CNPJ sob o nº 04.403.408/0001-65, e filiais (“PANASONIC”).
            </p>
            <p className="regulamento-text">
              1.2. A campanha não apresenta qualquer modalidade de sorteio, concurso, vale-brinde ou operação assemelhada, não estando vinculada à distribuição gratuita de prêmios nos termos da Lei 5.768/71, do Decreto n° 70.951/72 e da Portaria 41/2008 do Ministério da Economia. A campanha, portanto, beneficia indistintamente a todos os que cumprirem ao aqui disposto.
            </p>
            <p className="regulamento-title">2. DEFINIÇÕES</p>
            <p className="regulamento-text">
              2.1. Para a fiel interpretação do presente regulamento, as
              palavras definidas abaixo terão os significados atribuídos nesta
              cláusula
              <br />
              Cashback: Pontos disponibilizados a partir da apuração das notas
              fiscais com produtos Panasonic que poderão ser
              utilizados/resgatados nas opções de “Prêmios” disponibilizados no
              site{' '}
              <a href="www.cashbackpanasonic.com.br">
                www.cashbackpanasonic.com.br
              </a>
              .
              <br />
              Pontos: É a forma de como os participantes irão receber o seu
              cashback.
              <br />
              Prêmios: Opções de produtos, serviços e benefícios dentro do
              catálogo para utilização dos cashbacks ganhos.
              <br />
              Pana: Nome da moeda dentro do catálogo de prêmios.
            </p>
            <p className="regulamento-title">3. PARTICIPAÇÃO</p>
            <p className="regulamento-text">
              3.1. Durante o período de vigência da campanha, indicado na
              Cláusula 4.1, qualquer pessoa física interessada - com idade
              mínima de 18 (dezoito) anos completos, residente e domiciliada em
              território nacional; poderá participar desta campanha, desde que:
            </p>
            <p className="regulamento-text text-i">
              (i) adquira um dos produtos participantes (elencados na Cláusula 3.2) da marca Panasonic comercializados no e-commerce da empresa – https://loja.panasonic.com.br/ dentro do período de vigência da campanha;
            </p>
            <p className="regulamento-text text-i">
              (ii) o participante deverá completar o seu cadastro no site da campanha.
            </p>
            <p className="regulamento-text text-i">
              (iii) O CPF do participante cadastrado seja igual ao CPF indicado na validação da nota fiscal;
            </p>
            <p className="regulamento-text text-i">
              (iv) seja um consumidor final pessoa física, que utilize o produto para uso doméstico/ familiar. Parceiros e funcionários Panasonic também são elegíveis a participar da campanha;
            </p>
            <p className="regulamento-text text-i">
              (v) todas as demais condições estabelecidas neste regulamento
              tenham sido atendidas.
            </p>
            <p className="regulamento-text">
              3.1.1. A participação na campanha é voluntária e implica o
              conhecimento e aceitação de todos os termos e condições expressas
              no presente Regulamento e na Política de Privacidade, disponíveis
              no site:{' '}
              <a href="www.cashbackpanasonic.com.br">
                www.cashbackpanasonic.com.br
              </a>
              .
            </p>
            <p className="regulamento-text">
              3.1.2. Após o término da vigência da campanha, o participante terá
              até 3 dias corridos para o envio de nota fiscal (com chave SEFAZ)
              do produto participante no site.
            </p>
            <p className="regulamento-text">
              3.2. Participam desta campanha todos os modelos dos seguintes
              produtos da marca Panasonic: máquina de lavar roupas, micro-ondas
              e geladeiras (“Produtos Participantes”). Os produtos participantes
              podem sofrer variação durante a campanha sem aviso prévio.
            </p>
            <p className="regulamento-text">
              3.2.1. Para esta campanha não serão consideradas as compras de
              peças e acessórios, assim como a compra de produtos usados.
            </p>
            <p className="regulamento-title">4. VIGÊNCIA DA CAMPANHA</p>
            <p className="regulamento-text">
              4.1. O período de vigência da campanha será de 00h01 do dia
              18/04/2023 até às 23h59 do dia 14/05/2023. Nesse período,
              considera-se tanto a compra do produto participante quanto a
              criação do cadastro para a completa participação.
            </p>
            <p className="regulamento-text">
              4.1.1. Em nenhuma hipótese serão admitidas participações cujas compras e/ou cadastros sejam realizados antes do início ou após o término da campanha. Também não serão aceitas as participações cujas compras forem canceladas ou devolvidas pelo comprador/participante e o comprador/participante será excluído automaticamente da campanha.
            </p>
            <p className="regulamento-text">
              4.1.1.1. Caso o cashback tenha sido creditado antes do cancelamento ou devolução do produto pelo comprador/participante, o mesmo será retirado da conta.
            </p>
            <p className="regulamento-text">
              4.2. Esta campanha poderá encerrar-se antecipadamente, a
              qualquer momento e sem necessidade de aviso prévio, sem que
              qualquer indenização seja devida em razão disso. Nesta hipótese, o
              participante deverá ser avisado através do site
              www.cashbackpanasonic.com.br sobre o término da campanha.
            </p>
            <p className="regulamento-title">
              5. DAS CONDIÇÕES DE GANHO DE CASHBACK
            </p>
            <p className="regulamento-text">
              5.1. O participante, tendo realizado seu cadastro e compra de
              produtos Panasonic e respeitando as condições deste Regulamento,
              irá receber o cashback da sua compra em forma de PONTOS e os
              prêmios serão resgatados no site da campanha Panaclub, no item
              catálogo de prêmios. O participante poderá verificar seus pontos
              no extrato do catálogo de prêmios.
            </p>
            <p className="regulamento-text">
              5.2. Os produtos participantes da campanha e os valores que cada
              produto irá gerar de cashback aos participantes poderão ser
              informados através de canais de comunicação da campanha, como
              e-mail mkt, SMS ou no site da campanha.
            </p>
            <p className="regulamento-text">
              5.3. Não há limite de cashback em pontos por CPF. O participante
              poderá receber cashback a cada compra de um produto participante;
            </p>
            <p className="regulamento-text">
              5.4. No catálogo de prêmios cada ponto equivale a R$ 1,00, ou
              seja, UM “PANA” = R$ 1,00, cujos pontos poderão ser utilizados em
              produtos e serviços disponíveis no catálogo de prêmios. Nos preços
              dos produtos do catálogo de prêmios não estão incluídos os
              serviços de frete para entrega dos produtos adquiridos. O frete
              será pago diretamente pelos participantes, no momento da aquisição
              do prêmio escolhido, mediante a utilização de seus pontos.
            </p>
            <p className="regulamento-text">
              5.5. Após a validação dos dados da compra e confirmando a
              elegibilidade do participante a participar da campanha, o cashback
              será creditado na conta para utilização no catálogo de prêmios em
              até 10 dias corridos.
            </p>
            <p className="regulamento-text">
              5.6. Os participantes poderão resgatar sua pontuação em prêmios (produtos e/ou serviços disponíveis no catálogo de prêmios) em até 30 dias, a partir do momento da liberação. Em caso em que o participante deixar de utilizar dentro deste período, os pontos expirarão e o participante perderá o valor disponível.
            </p>
            <p className="regulamento-text">
              5.7. Os pontos não são negociáveis, não possuem valor monetário e
              não podem ser cedidos a terceiros, negociados ou trocados por
              dinheiro, sendo pessoais e intransferíveis.
            </p>
            <p className="regulamento-text">
              5.8. O participante tem ciência de que, a partir do momento que
              efetuar a troca dos pontos pelos produtos e/ou serviços
              disponíveis no catálogo de prêmios, a responsabilidade de entrega
              e/ou execução do serviço contratado será inteiramente do
              fornecedor/parceiro pelo participante escolhido no catálogo de
              prêmios.
            </p>
            <p className="regulamento-text">
              5.9. A prestação dos serviços ou entrega dos produtos disponíveis
              no catálogo de prêmios e/ou benefícios escolhidos no site da
              campanha serão de total e exclusiva responsabilidade do
              fornecedor/parceiro que prestar os serviços ou entregar os
              produtos/benefícios disponíveis catálogo de prêmios, conforme o
              que for escolhido pelo participante, ficando a Panasonic desde já
              isenta de qualquer tipo de responsabilidade por vícios e defeitos.
            </p>
            <p className="regulamento-text">
              5.11. Eventuais dúvidas, reclamações ou informações poderão ser
              obtidos através do site www.cashbackpanasonic.com.br , pelo
              telefone 0800 600 3345 ou pelo e-mail panaclub@grupoltm.com.br.
            </p>
            <p className="regulamento-text">
              5.12. Em nenhuma hipótese será realizado cashback diferente das
              condições aqui apresentadas.
            </p>
            <p className="regulamento-title">6. DISPOSIÇÕES GERAIS</p>
            <p className="regulamento-text">
              6.1. Este regulamento ficará disponível no site
              www.cashbackpanasonic.com.br
            </p>
            <p className="regulamento-text">
              6.2. O acesso à Internet ou uma linha telefônica são necessários
              para a realização da participação (cadastro e/ou envio dos
              documentos) nesta campanha. Sua qualidade pode variar de acordo
              com o tipo de conexão ou plano de telefonia, aparelho utilizado
              para acessar à Internet, provedor de Internet, região, entre
              outras variáveis como a indisponibilidade momentânea da rede e/ou
              do site.
            </p>
            <p className="regulamento-text">
              6.3. Tendo em vista as características do ambiente da Internet, a
              PANASONIC não se responsabiliza pelo cadastro do participante que
              porventura não for realizado, e/ou for feito de forma incompleta,
              ilegível, com rasuras e/ou de forma errônea (em desacordo com o
              presente regulamento), e/ou a falha no envio dos documentos
              ocasionados por problemas de conexão no servidor, nas linhas
              telefônicas ou em provedores de acesso dos usuários, ou ainda por
              falta de energia elétrica, sem exclusão das demais situações
              decorrentes de caso fortuito ou força maior.
            </p>
            <p className="regulamento-text">
              6.4. Caso, por qualquer razão alheia à vontade do participante ou
              da PANASONIC, houver interrupção no cadastramento e/ou no envio
              dos documentos de maneira que não seja concluída a operação, o
              participante terá nova oportunidade de efetivar o cadastramento,
              desde que seja realizado durante o período de vigência da
              campanha.
            </p>
            <p className="regulamento-text">
              6.5. O participante será excluído automaticamente desta campanha
              em caso de fraude comprovada através da obtenção de
              benefício/vantagem de forma ilícita ou pela infração ou não
              cumprimento de quaisquer das condições deste Regulamento. Para
              efeito deste item, considera-se fraude os seguintes exemplos, mas
              não se limitando a eles: a participação através do cadastramento
              de informações incorretas ou falsas; a participação de pessoas não
              elegíveis conforme critérios aqui estabelecidos; as participações
              que tenham sido efetuadas através de método robótico, automático,
              repetitivo, programado ou similar; a participação com a inserção
              de cupons fiscais/notas fiscais gerados por estabelecimento
              inexistente e/ou que não tem autorização/CNAE/registro para
              comercializar o produto adquirido.
            </p>
            <p className="regulamento-text">
              6.6. Os dados coletados serão tratados com confidencialidade e
              serão utilizados de acordo com as descrições da Política de
              Privacidade, sendo este documento acessível por meio do site da
              campanha.
            </p>
            <p className="regulamento-text">
              6.7. A participação na presente campanha implicará a integral
              aceitação das condições de sua realização, expressas no presente
              Regulamento, disponível no contexto desta campanha.
            </p>
            <p className="regulamento-text">
              6.8. A PANASONIC se reserva o direito de, a qualquer momento,
              alterar as condições desta campanha, do seu Regulamento ou de
              interrompê-la. Caso isso aconteça, a PANASONIC comunicará
              previamente o PARTICIPANTE por meio do site
              www.cashbackpanasonic.com.br.
            </p>
            <p className="regulamento-text">
              6.9. Mais informações ou solicitação de esclarecimentos poderão
              ser obtidos através do site www.cashbackpanasonic.com.br, pelo
              telefone 0800 600 3345 ou pelo e-mail panaclub@grupoltm.com.br.
            </p>
            <p className="regulamento-text">
              6.10. O consumidor participante deve guardar os documentos e
              informações que forem apresentados por ele no momento da promoção,
              pois tem ciência de que poderão ser solicitados pela PANASONIC o
              envio físico ou por e-mail (a critério da PANASONIC) para
              confirmação da regularidade do cadastro, como, mas não se
              limitando a: comprovantes fiscais/notas fiscais, documentação de
              identificação, etiqueta com informações do modelo e número de
              série do produto, comprovação de endereço com prazo de emissão
              máxima de 3 (três) meses contados da data do pedido. A recusa em
              apresentar documentos, ou a apresentação de documentos com sinais
              claros de adulteração ou sem condições de leitura ou verificação,
              ensejará a desclassificação do participante.
            </p>
          </div>
        </div>
      </Modal.Body>
    </ModalStyle>
  </Modal>
);

const ModalProdutosParticipantes = (props) => (
  <Modal
    {...props}
    size="xl"
    aria-labelledby="modalProdutosParticipantes"
    centered
    dialogClassName="modal-bootstrap-custom"
    scrollable
  >
    <ModalStyleProdutos>
      <Modal.Body>
        <div className="row">
          <div className="col-12">
            <div className="table-responsive">
              <table className="table table-produtos">
                <thead>
                  <th className="p-th">SKU VTEX</th>
                  <th className="p-th">EAN</th>
                  <th className="p-th">NOME</th>
                  <th className="p-th">PANAS </th>
                  <th>
                    {' '}
                    <button
                      type="button"
                      className="btn-close-modal"
                      onClick={() =>
                        props.setShowModalProdutosParticipantes(false)
                      }
                    >
                      <img src={ModalCloseBtn} alt="" />
                    </button>
                  </th>
                </thead>
                <tbody className="table-responsive">
                  <tr>
                    <td>NN-ST67LSRUN</td>
                    <td>7896067222515</td>
                    <td>900 W Potência | Dupla refeição | Antibacteria Ag | Antiaderente | Função Pega fácil | 6 Receitas préprogramadas  | Desodorizador | 2 opções de Auto Descongelamento | 3 posições de Memória | Eficiência energética A | Teclado Numérico | Porta espelhada</td>
                    <td>50</td>
                  </tr>
                  <tr>
                    <td>NN-ST67LSRUK</td>
                    <td>7896067222508</td>
                    <td>900 W Potência | Dupla refeição | Antibacteria Ag | Antiaderente | Função Pega fácil | 6 Receitas préprogramadas  | Desodorizador | 2 opções de Auto Descongelamento | 3 posições de Memória | Eficiência energética A | Teclado Numérico | Porta espelhada</td>
                    <td>50</td>
                  </tr>
                  <tr>
                    <td>NN-ST65LWRUN</td>
                    <td>7896067222492</td>
                    <td>900 W Potência | Dupla refeição | Antibacteria Ag | Antiaderente | Função Pega fácil | 6 Receitas préprogramadas  | Desodorizador | 2 opções de Auto Descongelamento | 3 posições de Memória | Eficiência energética A | Teclado Numérico </td>
                    <td>50</td>
                  </tr>
                  <tr>
                    <td>NN-ST65LWRUK</td>
                    <td>7896067222485</td>
                    <td>900 W Potência | Dupla refeição | Antibacteria Ag | Antiaderente | Função Pega fácil | 6 Receitas préprogramadas  | Desodorizador | 2 opções de Auto Descongelamento | 3 posições de Memória | Eficiência energética A | Teclado Numérico </td>
                    <td>50</td>
                  </tr>
                  <tr>
                    <td>NN-ST55LMRUN</td>
                    <td>7896067222478</td>
                    <td>800 W Potência | Dupla refeição | Antibacteria Ag | Antiaderente | Função Pega fácil | 3 Receitas préprogramadas  | Desodorizador | 2 opções de Auto Descongelamento | 3 posições de Memória | Eficiência energética A | Teclado Numérico</td>
                    <td>50</td>
                  </tr>
                  <tr>
                    <td>NN-ST55LMRUK</td>
                    <td>7896067222461</td>
                    <td>800 W Potência | Dupla refeição | Antibacteria Ag | Antiaderente | Função Pega fácil | 3 Receitas préprogramadas  | Desodorizador | 2 opções de Auto Descongelamento | 3 posições de Memória | Eficiência energética A | Teclado Numérico </td>
                    <td>50</td>
                  </tr>
                  <tr>
                    <td>NN-GT68LBRUN</td>
                    <td>7896067222539</td>
                    <td>Grill (potência grill 1000W) | 900W Potência | Smartsense (6 receitas com sensor) + 5 receitas préprogramadas | Dupla refeição | Antibacteria Ag | Antiaderente | Função Pega fácil | Desodorizador | 2 opções de Auto Descongelamento | 3 posições de Memória | Eficiência energética A</td>
                    <td>50</td>
                  </tr>
                  <tr>
                    <td>NN-GT68LBRUK</td>
                    <td>7896067222522</td>
                    <td>Grill (potência grill 1000W) | 900W Potência | Smartsense (6 receitas com sensor) + 5 receitas préprogramadas | Dupla refeição | Antibacteria Ag | Antiaderente | Função Pega fácil | Desodorizador | 2 opções de Auto Descongelamento | 3 posições de Memória | Eficiência energética A</td>
                    <td>50</td>
                  </tr>
                  <tr>
                    <td>NN-ST27LWRUN</td>
                    <td>7896067222430</td>
                    <td>700 W Potência | Antibacteria Ag | Antiaderente | Função Pega fácil | 3 Receitas préprogramadas  | Desodorizador | 2 opções de Auto Descongelamento | 3 posições de Memória | Eficiência energética A | Teclado Numérico | Porta espelhada</td>
                    <td>50</td>
                  </tr>
                  <tr>
                    <td>NN-ST27LWRUK</td>
                    <td>7896067222447</td>
                    <td>700 W Potência | Antibacteria Ag | Antiaderente | Função Pega fácil | 3 Receitas préprogramadas  | Desodorizador | 2 opções de Auto Descongelamento | 3 posições de Memória | Eficiência energética A | Teclado Numérico | Porta espelhada</td>
                    <td>50</td>
                  </tr>
                  <tr>
                    <td>NN-ST25LWRUN</td>
                    <td>7896067222416</td>
                    <td>700 W Potência | Antibacteria Ag | Antiaderente | Função Pega fácil | 3 Receitas préprogramadas  | Desodorizador | 2 opções de Auto Descongelamento | 3 posições de Memória | Eficiência energética A | Teclado Numérico | Porta espelhada</td>
                    <td>50</td>
                  </tr>
                  <tr>
                    <td>NN-ST25LWRUK</td>
                    <td>7896067222423</td>
                    <td>700 W Potência | Antibacteria Ag | Antiaderente | Função Pega fácil | 3 Receitas préprogramadas  | Desodorizador | 2 opções de Auto  Descongelamento | 3 posições de Memória | Eficiência energética A | Teclado Numérico | Porta Branca</td>
                    <td>50</td>
                  </tr>
                  <tr>
                    <td>NN-ST66NBRUK</td>
                    <td>7896067222577</td>
                    <td>1450W Potência | 6 Receitas Pré-Programadas  | Tecla Desodorizador | 2 opções de Auto Descongelamento | 3 posições de Memória |  Função Mudo I Dupla Refeição I Função Pega Fácil I Adicionar 30s I Relógio I Tempo de Espera I Trava de Segurança Eletrônica. </td>
                    <td>50</td>
                  </tr>
                  <tr>
                    <td>NN-ST66NBRUN</td>
                    <td>7896067222560</td>
                    <td>1450W Potência | 6 Receitas Pré-Programadas  | Tecla Desodorizador | 2 opções de Auto Descongelamento | 3 posições de Memória |  Função Mudo I Dupla Refeição I Função Pega Fácil I Adicionar 30s I Relógio I Tempo de Espera I Trava de Segurança Eletrônica. </td>
                    <td>50</td>
                  </tr>
                  <tr>
                    <td>ER-GK80-S572</td>
                    <td>7896067298817</td>
                    <td>Aparador Corporal V-RAZOR, lâmina em aço inoxidável, resistente à água, bateria recarregável, Bivolt - Panasonic.</td>
                    <td>50</td>
                  </tr>
                  <tr>
                    <td>ER-GK20-K572</td>
                    <td>7896067298947</td>
                    <td>Aparador Corporal Mini V-RAZOR, lâmina em aço inoxidável, resistente à água, portátil, a pilha - Panasonic.</td>
                    <td>50</td>
                  </tr>
                  <tr>
                    <td>EW-DJ10-A503</td>
                    <td>7896067298800</td>
                    <td>Irrigador oral portátil com 2 níveis de pressão, capacidade de água de 165ml, resistente à água, a pilha - Panasonic</td>
                    <td>50</td>
                  </tr>
                  <tr>
                    <td>NA-F160B6WA</td>
                    <td>7896067227541</td>
                    <td>Lavadora 16kg com a melhor lavagem e o menor consumo de água da categoria, segundo o Inmetro Possui programa exclusivo de lavagem Vanish com 20% mais desempenho, funfção Antibacteria AG que elimina a proliferacão de 99,9% das bacterias, função de reuso de água, espuma ativa, Sistema Ciclone de lavagem sem agitador, Smartsense que pesa as roupas e escolhe a quantidade ideal de água e cesto em inox</td>
                    <td>100</td>
                  </tr>
                  <tr>
                    <td>NA-F160B6WB</td>
                    <td>7896067227558</td>
                    <td>Lavadora 16kg com a melhor lavagem e o menor consumo de água da categoria, segundo o Inmetro Possui programa exclusivo de lavagem Vanish com 20% mais desempenho, funfção Antibacteria AG que elimina a proliferacão de 99,9% das bacterias, função de reuso de água, espuma ativa, Sistema Ciclone de lavagem sem agitador, Smartsense que pesa as roupas e escolhe a quantidade ideal de água e cesto em inox</td>
                    <td>100</td>
                  </tr>
                  <tr>
                    <td>NA-F130B1WA</td>
                    <td>7896067227817</td>
                    <td>Máquina de Lavar Exclusiva Panasonic Função Vanish 13kg Branca</td>
                    <td>100</td>
                  </tr>
                  <tr>
                    <td>NA-F130B1WB</td>
                    <td>7896067227824</td>
                    <td>Máquina de Lavar Exclusiva Panasonic Função Vanish 13kg Branca</td>
                    <td>100</td>
                  </tr>
                  <tr>
                    <td>NN-CD89NBRUK</td>
                    <td>7896067222591</td>
                    <td>Micro-ondas 4 em 1, com função grill, airfryer e convecção. 30L | 1400W Potência | 11 Receitas pré-programadas (Airfryer) | Função Combinar | Função Descongelar | Eficiência energética B (cavidade em aço). </td>
                    <td>100</td>
                  </tr>
                  <tr>
                    <td>NN-CD89NBRUN</td>
                    <td>7896067222584</td>
                    <td>Micro-ondas 4 em 1, com função grill, airfryer e convecção. 30L | 1400W Potência | 11 Receitas pré-programadas (Airfryer) | Função Combinar | Função Descongelar | Eficiência energética B (cavidade em aço). </td>
                    <td>100</td>
                  </tr>
                  <tr>
                    <td>EAH-AZ40P-K</td>
                    <td>7896067298992</td>
                    <td>Fone De Ouvido True Wireless</td>
                    <td>100</td>
                  </tr>
                  <tr>
                    <td>EAH-AZ40P-S</td>
                    <td>7896067299005</td>
                    <td>Fone De Ouvido True Wireless</td>
                    <td>100</td>
                  </tr>
                  <tr>
                    <td>EAH-AZ60P-S</td>
                    <td>7896067298985</td>
                    <td>Fone De Ouvido True Wireless Com Cancelamento De Ruído</td>
                    <td>100</td>
                  </tr>
                  <tr>
                    <td>NR-BB53GV3BA</td>
                    <td>7896067226643</td>
                    <td>FROST FREE |  425L |  2 PORTAS | BOTTOM FREEZER | Painel Easy Touch | INVERTER: 36% de economia | ECONAVI*: +10% de economia | Vitamin Power: Potencializa Vitamina C e D | Climate Control*: Preserva melhor frutas e verduras | Prateleira Retrátil | OBS: *Exclusividade Panasonic</td>
                    <td>200</td>
                  </tr>
                  <tr>
                    <td>NR-BB53GV3BB</td>
                    <td>7896067226650</td>
                    <td>FROST FREE |  425L |  2 PORTAS | BOTTOM FREEZER | Painel Easy Touch | INVERTER: 36% de economia | ECONAVI*: +10% de economia | Vitamin Power: Potencializa Vitamina C e D | Climate Control*: Preserva melhor frutas e verduras | Prateleira Retrátil | OBS: *Exclusividade Panasonic</td>
                    <td>200</td>
                  </tr>
                  <tr>
                    <td>NR-BB53PV3XA</td>
                    <td>7896067226834</td>
                    <td>FROST FREE |  425L |  2 PORTAS | BOTTOM FREEZER  | INVERTER: mais de 36% de economia de energia | ECONAVI*: Mais 10% de economia | Vitamin Power: Potencializa Vitamina C e D | Climate Control*: Preserva melhor frutas e verduras | Prateleira Retrátil | OBS: *Exclusividade Panasonic</td>
                    <td>200</td>
                  </tr>
                  <tr>
                    <td>NR-BB53PV3XB</td>
                    <td>7896067226841</td>
                    <td>FROST FREE |  425L |  2 PORTAS | BOTTOM FREEZER  | INVERTER: mais de 36% de economia de energia | ECONAVI*: Mais 10% de economia | Vitamin Power: Potencializa Vitamina C e D | Climate Control*: Preserva melhor frutas e verduras | Prateleira Retrátil | OBS: *Exclusividade Panasonic</td>
                    <td>200</td>
                  </tr>
                  <tr>
                    <td>NR-BB53PV3TA</td>
                    <td>7896067227978</td>
                    <td>FROST FREE | 425L | 2 PORTAS | BOTTOM FREEZER | INVERTER: Mais de 36% de economia de energia | SMARTSENSE* I Vitamin Power: Potencializa Vitamina C e D | Climate Control*: Preserva melhor frutas e verduras I Desodorizador I Gavetão Horti Fruti I Compartimento Extra Frio I Drink Cooler I Turbo Freezer | Prateleira Retrátil | OBS: *Exclusividade Panasonic. - LANÇAMENTO PREVISTO ABRIL/2023</td>
                    <td>200</td>
                  </tr>
                  <tr>
                    <td>NR-BB53PV3TB</td>
                    <td>7896067227985</td>
                    <td>FROST FREE | 425L | 2 PORTAS | BOTTOM FREEZER | INVERTER: Mais de 36% de economia de energia | SMARTSENSE* I Vitamin Power: Potencializa Vitamina C e D | Climate Control*: Preserva melhor frutas e verduras I Desodorizador I Gavetão Horti Fruti I Compartimento Extra Frio I Drink Cooler I Turbo Freezer | Prateleira Retrátil | OBS: *Exclusividade Panasonic. - LANÇAMENTO PREVISTO ABRIL/2023</td>
                    <td>200</td>
                  </tr>
                  <tr>
                    <td>NR-BB41GV1BA</td>
                    <td>7896067227893</td>
                    <td>FROST FREE | 397L | 2 PORTAS | BOTTOM FREEZER | INVERTER: mais de 40%* de economia de energia | SMARTSENSE* | BLACK GLASS</td>
                    <td>200</td>
                  </tr>
                  <tr>
                    <td>NR-BB41GV1BB</td>
                    <td>7896067227909</td>
                    <td>FROST FREE | 397L | 2 PORTAS | BOTTOM FREEZER | INVERTER: mais de 40%* de economia de energia | SMARTSENSE* | BLACK GLASS</td>
                    <td>200</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Modal.Body>
    </ModalStyleProdutos>
  </Modal>
);

const ModalComoParticipar = (props) => (
  <Modal
    {...props}
    size="lg"
    aria-labelledby="modalComoParticipar"
    centered
    dialogClassName="modal-bootstrap-custom modal-como-participar"
  >
    <ModalStyleComoParticipar>
      <Modal.Body>
        <div className="row ">
          <div className="col-9">
            <p className="modal-title" />
          </div>
          <div className="col-3 text-align-right">
            <button
              type="button"
              className="btn-close-modal"
              onClick={() => props.setShowModalComoParticipar(false)}
            >
              <img src={ModalCloseBtn} alt="" />
            </button>
          </div>
        </div>
        <p className="text-center">
          <strong>01 {`>`} </strong> Acesse "
          <string className="color-blue">https://www.panaclub.com.br</string>"
        </p>
        <img src={Passo1} alt="" aria-hidden />
        <p className="text-center">
          <strong>02 {`>`} </strong> Clique em "
          <string className="color-blue">Pré-cadastro do Consumidor</string>"
        </p>
        <img src={Passo2} alt="" aria-hidden />
        <p className="text-center">
          <strong>03 {`>`} </strong> Informe os dados solicitados: CPF / Data de
          Nascimento / E-mail e a Chave de Acesso da NF-e
        </p>
        <img src={Passo3} alt="" aria-hidden />
        <p className="text-center">
          <strong>04 {`>`} </strong> Leia e aceite o
          <string className="color-blue"> Termo de Aceite</string> e clique em
          enviar.
        </p>
        <img src={Passo4} alt="" aria-hidden />
      </Modal.Body>
    </ModalStyleComoParticipar>
  </Modal>
);

const Home = () => {
  const [showModalPolitica, setShowModalPolitica] = useState(false);
  const [showModalRegulamento, setShowModalRegulamento] = useState(false);
  const [showModalComoParticipar, setShowModalComoParticipar] = useState(false);
  const [showModalProdutosParticipantes, setShowModalProdutosParticipantes] =
    useState(false);
  const [cashbackNumbers, setCashbackNumbers] = useState([]);

  useEffect(() => {
    const getCashback = async () => {
      const res = await api.get('/saldo');
      if (res.data) {
        const totalCashback = res.data.cashbackDisponivel < 0 ? [0] : res.data.cashbackDisponivel.toString().split('');

        setCashbackNumbers(totalCashback);
      }
    };
    getCashback();
  }, []);

  return (
    <div className="main-container">
      <MainContainer>
        <div className="container" id="header">
          <div className="d-md-none">
            <nav className="navbar navbar-light bg-dark">
              <div className="container-fluid">
                <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                >
                  <span className="navbar-toggler-icon" />
                </button>
                <div
                  className="collapse navbar-collapse"
                  id="navbarSupportedContent"
                >
                  <div className="text-center">
                    <img
                      src={LogoFooter}
                      alt="Logo panasonic"
                      id="logo-header-mobile"
                    />
                  </div>
                  <br />
                  <a href="#o-que-e">O QUE É | </a>
                  <br />
                  <a href="#como-participar">COMO PARTICIPAR | </a>
                  <br />
                  <a href="#produtos">PRODUTOS | </a>
                  <br />
                  <button
                    type="button"
                    onClick={() => setShowModalRegulamento(true)}
                    id="btn-regulamento"
                  >
                    REGULAMENTO |
                  </button>
                  <br />
                  <button
                    type="button"
                    onClick={() => setShowModalPolitica(true)}
                  >
                    POLÍTICA DE PRIVACIDADE |
                  </button>
                  <br />
                  <a href="#duvidas"> DÚVIDAS | </a>
                  <br />
                  <a href="https://www.panaclub.com.br/Login.aspx">ENTRAR </a>
                </div>
              </div>
            </nav>
          </div>
          <div className="row d-mobile-none">
            <div className="col-sm-12 col-md-3">
              <img src={LogoFooter} alt="Logo panasonic" id="logo-header" />
            </div>
            <div className="col-sm-12 col-md-9">
              <a href="#o-que-e">O QUE É | </a>
              <a href="#como-participar">COMO PARTICIPAR | </a>
              <a href="#produtos">PRODUTOS | </a>
              <button
                type="button"
                onClick={() => setShowModalRegulamento(true)}
                id="btn-regulamento"
              >
                REGULAMENTO |
              </button>
              <button type="button" onClick={() => setShowModalPolitica(true)}>
                POLÍTICA DE PRIVACIDADE |
              </button>
              <a href="#duvidas"> DÚVIDAS | </a>
              <a href="https://www.panaclub.com.br/Login.aspx">ENTRAR </a>
            </div>
          </div>
          <div className="row criado-pra-voce">
            <div className="col-12">
              <img src={PraVoce} alt="Criado pra você" id="pra-voce" />
            </div>
          </div>
          <div className="row text-center">
            <div className="col-12">
              <img src={ImgDestaque} alt="" aria-hidden id="img-destaque" />
            </div>
          </div>
          {/* <div className="row d-mobile-none">
            <div className="col-12 text-center">
              <img src={TotalDisponivelBox} alt="" aria-hidden />
              <div id="panel-number-box-img">
                {cashbackNumbers.map((number) => (
                  <span>{number}</span>
                ))}
              </div>
            </div>
          </div> */}
          {/* <div className="row d-md-none">
            <div className="col-12 text-center">
              <p id="total-disponivel-mobile">TOTAL DE CASHBACK DISPONÍVEL</p>
              <div id="panel-number-box-img-mobile">
                {cashbackNumbers.map((number) => (
                  <span>{number}</span>
                ))}
              </div>
            </div>
          </div> */}
          <div className="row text-center position-relative" id="o-que-e">
            <div className="col-sm-12 col-md-12">
              <p className="periodo">
                Campanha encerrada, devido ao atingimento dos 1.050 cashback que disponibilizamos
              </p>
            </div>
            <div className="col-sm-12 text-center col-md-12 desktop-logo-pana">
              <img src={LogoPanaclub} alt="" aria-hidden id="logo-panaclub" />
            </div>
          </div>
        </div>
      </MainContainer>
      <SecondContainer>
        <div className="container">
          <div className="row text-center" id="como-participar">
            <div className="col-12">
              <h2 className="title">COMO PARTICIPAR</h2>
            </div>
          </div>
          <div className="row justify-content-center text-center mt-5">
            <div className="col-sm-12 col-md-3">
              <div className="participate-box">
                <div className="icon-box">
                  <img src={ImgIconBag} alt="" aria-hidden />
                </div>
                <div className="text-box">
                  <p>COMPRE PRODUTOS PARTICIPANTES PANASONIC</p>
                  <p id="text-onde">
                    EM LOJAS ONLINE PANASONIC
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-3">
              <div className="participate-box">
                <div className="icon-box">
                  <img src={ImgIconRefund} alt="" aria-hidden />
                </div>
                <div className="text-box">
                  <p>RECEBA CASHBACK EM PANAS</p>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-3">
              <div className="participate-box">
                <div className="icon-box">
                  <img src={ImgIconGift} alt="" aria-hidden />
                </div>
                <div className="text-box">
                  <p>RESGATE PRÊMIOS</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-5 text-center">
            <div className="col-12">
              <ReactPlayer
                url="https://vimeo.com/781913565"
                className="react-player"
                controls
                muted
                playing
              />
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-sm-12 col-md-12">
              <p className="periodo">
                Campanha encerrada, devido ao atingimento dos 1.050 cashback que disponibilizamos
              </p>
            </div>
            <div className="d-desk-none col-12">
              <a
                href="https://www.panaclub.com.br/Login.aspx"
                className="btn btn-participe"
              >
                Participe
              </a>
            </div>
            <div className="d-mobile-none offset-md-9 col-3">
              <a
                href="https://www.panaclub.com.br/Login.aspx"
                className="btn btn-participe"
              >
                Participe
              </a>
            </div>
          </div>
        </div>
      </SecondContainer>
      <ThirdContainer>
        <div className="container">
          <div className="row text-center" id="produtos">
            <div className="col-12">
              <h3 className="title">PRODUTOS</h3>
            </div>
          </div>
          <div className="row text-center mt-3">
            <div className="col-12 d-center">
              <p className="card-description">
                Aqui você confere os produtos que dão cashback
              </p>
            </div>
          </div>
          <div className="row text-center mt-produtos">
            <div className="col-12">
              <img src={RetornoCashback} alt="" aria-hidden />
            </div>
          </div>
          <div className="row text-center mt-5">
            <div className="col-sm-12 col-md-12">
              <button
                type="button"
                id="produtos-participantes"
                onClick={() => {
                  setShowModalProdutosParticipantes(true);
                }}
              >
                PRODUTOS PARTICIPANTES
              </button>
            </div>
          </div>
          <div className="row text-center" id="duvidas">
            <div className="col-12">
              <h3 className="title">DÚVIDAS</h3>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-12">
              <Accordion defaultActiveKey={0} flush>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    QUEM PODE PARTICIPAR DA CAMPANHA?
                  </Accordion.Header>
                  <Accordion.Body>
                    Durante o período de 18/04/2023 até às 23:59 do dia
                    14/05/2023, poderão participar desta ação toda e qualquer
                    pessoa física, maior de 18 (dezoito) anos, residente e
                    domiciliado no território Nacional e/ou pessoa jurídica que
                    adquirir um produto Panasonic, nos termos do regulamento.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    QUAL O PERÍODO DE PARTICIPAÇÃO E CADASTRO?
                  </Accordion.Header>
                  <Accordion.Body>
                    Campanha encerrada, devido ao atingimento dos 1.050 cashback que disponibilizamos
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    QUAIS OS PRODUTOS PARTICIPANTES?
                  </Accordion.Header>
                  <Accordion.Body>
                    Participam dessa ação:
                    <br /><br />
                    Micro-ondas:
                    <br />
                    <table>
                      <thead>
                        <tr>
                          <th>SKu's</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>NN-ST67LSRUN</td>
                        </tr>
                        <tr>
                          <td>NN-ST67LSRUK</td>
                        </tr>
                        <tr>
                          <td>NN-ST65LWRUN</td>
                        </tr>
                        <tr>
                          <td>NN-ST65LWRUK</td>
                        </tr>
                        <tr>
                          <td>NN-ST55LMRUN</td>
                        </tr>
                        <tr>
                          <td>NN-ST55LMRUK</td>
                        </tr>
                        <tr>
                          <td>NN-GT68LBRUN</td>
                        </tr>
                        <tr>
                          <td>NN-GT68LBRUK</td>
                        </tr>
                        <tr>
                          <td>NN-ST27LWRUN</td>
                        </tr>
                        <tr>
                          <td>NN-ST27LWRUK </td>
                        </tr>
                        <tr>
                          <td>NN-ST25LWRUN</td>
                        </tr>
                        <tr>
                          <td>NN-ST25LWRUK</td>
                        </tr>
                        <tr>
                          <td>NN-ST66NBRUK </td>
                        </tr>
                        <tr>
                          <td>NN-ST66NBRUN</td>
                        </tr>
                        <tr>
                          <td>NN-CD89NBRUK</td>
                        </tr>
                        <tr>
                          <td>NN-CD89NBRUN </td>
                        </tr>
                      </tbody>
                    </table>
                    <br /><br />
                    Máquinas de Lavar:
                    <br />
                    <table>
                      <thead>
                        <tr>
                          <th>SKu's</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>NA-F160B6WA </td>
                        </tr>
                        <tr>
                          <td>NA-F160B6WB </td>
                        </tr>
                        <tr>
                          <td>NA-F130B1WA  </td>
                        </tr>
                        <tr>
                          <td>NA-F130B1WB </td>
                        </tr>
                      </tbody>
                    </table>
                    <br /><br />
                    Geladeiras
                    <br />
                    <table>
                      <thead>
                        <tr>
                          <th>SKu's</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>NR-BB53GV3BA </td>
                        </tr>
                        <tr>
                          <td>NR-BB53GV3BB</td>
                        </tr>
                        <tr>
                          <td>NR-BB53PV3XA </td>
                        </tr>
                        <tr>
                          <td>NR-BB53PV3XB </td>
                        </tr>
                        <tr>
                          <td>NR-BB53PV3TA</td>
                        </tr>
                        <tr>
                          <td>NR-BB53PV3TB</td>
                        </tr>
                        <tr>
                          <td>NR-BB41GV1BA </td>
                        </tr>
                        <tr>
                          <td>NR-BB41GV1BB </td>
                        </tr>
                      </tbody>
                    </table>
                    <br /><br />
                    Fones de ouvido
                    <br />
                    <table>
                      <thead>
                        <tr>
                          <th>SKu's</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>EAH-AZ40P-K</td>
                        </tr>
                        <tr>
                          <td>EAH-AZ40P-S </td>
                        </tr>
                        <tr>
                          <td>EAH-AZ60P-S</td>
                        </tr>
                      </tbody>
                    </table>
                    <br /><br />
                    Irrigador Oral
                    <br />
                    <table>
                      <thead>
                        <tr>
                          <th>SKu's</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>EW-DJ10-A503</td>
                        </tr>
                      </tbody>
                    </table>
                    <br /><br />
                    Aparador Corporal
                    <br />
                    <table>
                      <thead>
                        <tr>
                          <th>SKu's</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>ER-GK80-S572</td>
                        </tr>
                        <tr>
                          <td>ER-GK20-K572 </td>
                        </tr>
                      </tbody>
                    </table>
                    <br /><br />
                    Importante: Os produtos devem ser novos.
                    <br />
                    Para esta Promoção não serão consideradas as compras de peças e assessórios, bem como a compra de produtos usados.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>
                    COMO FAÇO PARA PARTICIPAR DA CAMPANHA “CASHBACK PANASONIC”?
                  </Accordion.Header>
                  <Accordion.Body>
                    Para participar da promoção basta comprar um dos produtos participantes no e-commerce Panasonic e acessar cashbackpanasonic.com.br. No campo “Entrar” clicar em “Primeiro Acesso”, informar CPF e data de nascimento, e clicar em acessar.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <Accordion.Header>
                    POSSO CADASTRAR MAIS DE UMA VEZ A MESMA NOTA FISCAL?
                  </Accordion.Header>
                  <Accordion.Body>
                    Não. Poderá ser efetuado o cadastro apenas 1 (uma) única
                    vez. Tentativas posteriores serão bloqueadas.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                  <Accordion.Header>
                    POSSO ME CADASTRAR MAIS DE UMA VEZ?
                  </Accordion.Header>
                  <Accordion.Body>
                    Não. Na hipótese de a Nota Fiscal ter mais de um produto
                    participante, o valor do cashback equivalerá um reembolso,
                    dentro do limite informado por CPF cadastrado.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="8">
                  <Accordion.Header>
                    QUAL SERÁ O VALOR DO REEMBOLSO?
                  </Accordion.Header>
                  <Accordion.Body>
                    O participante tendo realizado seu cadastro e, desde que
                    respeitadas as condições do regulamento, poderá receber 1
                    (um) reembolso na seguinte proporção:
                    <br />
                    <br />- Micro-ondas – Reembolso até valor de 100 Panas = R$100,00
                    <br />- Máquina de Lavar – Reembolso no valor de 100 Panas =
                    R$100,00
                    <br />- Geladeira –Reembolso no valor de 200 Panas =
                    R$200,00
                    <br />- Fones de Ouvido - Reembolso no valor de 100 Panas = R$100,00
                    <br />-  Irrigador Oral - Reembolso no valor de 50 Panas = R$50,00
                    <br />- Aparador Corporal - Reembolso no valor de 50 Panas = R$50,00
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="9">
                  <Accordion.Header>
                    COMO FAÇO PARA SABER SE A MINHA PARTICIPAÇÃO ESTÁ VALIDADA?
                  </Accordion.Header>
                  <Accordion.Body>
                    O consumidor será comunicado pelo e-mail cadastrado no site
                    sobre a aprovação ou reprovação. Sugerimos verificar a
                    lixeira ou spam dos seus e-mails. O endereço de e-mail não
                    estará apto a devolver respostas com dúvidas e/ou perguntas.
                    Qualquer dúvida, deve-se entrar em contato com a central de
                    atendimento.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="10">
                  <Accordion.Header>
                    QUAL É O PRAZO PARA EU RECEBER O REEMBOLSO?
                  </Accordion.Header>
                  <Accordion.Body>
                    Atendidas todas as condições estabelecidas em Regulamento, a
                    Panasonic realizará o reembolso do valor conforme regras
                    previstas no regulamento em até 10 dias úteis. O valor será
                    creditado em um catálogo de prêmios plugado ao
                    www.cashbackpanasonic.com.br e www.panaclub.com.br. <br />
                    Não estão apto reembolso em conta corrente, conta de
                    investimento, conta de governo e a conta salário.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="11">
                  <Accordion.Header>
                    ESQUECI MINHA SENHA DE CADASTRO. E AGORA?
                  </Accordion.Header>
                  <Accordion.Body>
                    Para reativar a sua senha, clique em “Esqueci minha Senha” e
                    digite seu endereço de e-mail. Você receberá um e-mail com
                    as informações necessárias para recuperá-la.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="12">
                  <Accordion.Header>
                    QUAIS OS CASOS EM QUE O REEMBOLSO NÃO SERÁ EFETIVADO?
                  </Accordion.Header>
                  <Accordion.Body>
                    Na hipótese da ocorrência de verificação e/ou comprovação de
                    fraude e/ou tentativa de burlar as disposições do presente
                    Regulamento e suas regras, o consumidor será automaticamente
                    excluído da Campanha, sem prejuízo de ser responsabilizado
                    civil e criminalmente conforme previsto em lei.
                    <br />
                    Também não será efetivado caso o envio do comprovante não
                    cumpra as regas previstas no regulamento.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="13">
                  <Accordion.Header>
                    FUI APROVADO E NÃO RECEBI O REEMBOLSO
                  </Accordion.Header>
                  <Accordion.Body>
                    Você deverá entrar em contato com a central de atendimento
                    Panaclub através do número 0800 600 3345, de segunda a
                    sexta-feira, onde será feita uma consulta no sistema para
                    verificar.
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
          <div className="row mt-5" id="footer">
            <div className="col-sm-12 col-md-9">
              <p>
                <strong>Central de atendimento: 0800 600 3345 </strong>
                Copyright 2022, Panasonic do Brasil Limitada. Todos os direitos
                reservados.
              </p>
            </div>
            <div className="col-sm-12 col-md-3 text-right">
              <img src={LogoFooter} alt="Logo panasonic" id="logo-footer" />
            </div>
          </div>
        </div>
      </ThirdContainer>

      <ModalPolitica
        show={showModalPolitica}
        onHide={() => setShowModalPolitica(false)}
        setShowModalPolitica={setShowModalPolitica}
      />
      <ModalRegulamento
        show={showModalRegulamento}
        onHide={() => setShowModalRegulamento(false)}
        setShowModalRegulamento={setShowModalRegulamento}
      />
      <ModalComoParticipar
        show={showModalComoParticipar}
        onHide={() => setShowModalComoParticipar(false)}
        setShowModalComoParticipar={setShowModalComoParticipar}
      />
      <ModalProdutosParticipantes
        show={showModalProdutosParticipantes}
        onHide={() => setShowModalProdutosParticipantes(false)}
        setShowModalProdutosParticipantes={setShowModalProdutosParticipantes}
      />
    </div>
  );
};

export default Home;